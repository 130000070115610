@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

@font-face {
  font-family: "TCCC Unity Text";
  font-display: swap;
  src: local("TCCC-UnityText Regular"), local("TCCC-UnityText-Regular"),
    url("./assets/fonts/tccc-unity/tccc-unitytext-regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "TCCC Unity Text";
  font-display: swap;
  src: local("TCCC-UnityText Medium"), local("TCCC-UnityText-Medium"),
    url("./assets/fonts/tccc-unity/tccc-unitytext-medium.woff") format("woff");
  font-weight: 500 600;
}

@font-face {
  font-family: "TCCC Unity Text";
  font-display: swap;
  src: local("TCCC-UnityText Bold"), local("TCCC-UnityText-Bold"),
    url("./assets/fonts/tccc-unity/tccc-unitytext-bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "TCCC Unity Text";
  font-display: swap;
  src: local("TCCC-UnityText Light"), local("TCCC-UnityText-Light"),
    url("./assets/fonts/tccc-unity/TCCC-UnityText-Light.ttf") format("truetype");
  font-weight: 100 300;
}

@font-face {
  font-family: "TCCC Unity Text";
  font-display: swap;
  src: local("TCCC-UnityText Black"), local("TCCC-UnityText-Black"),
    url("./assets/fonts/tccc-unity/TCCC-UnityText-Black.ttf") format("truetype");
  font-weight: 800 900;
}

:root {
  --TCCC-Black: #000000;
  --TCCC-White: #ffffff;
  --TCCC-Grey-03: #d4d4d4;
  --TCCC-Grey-04: #9b9b9b;
  --TCCC-Grey-04---Background: #9b9b9b19;
  --TCCC-Grey-06: #505050;
  --TCCC-Grey-06---Background: #50505019;
  --Brands-Coca-cola-Primary-Dark: #de0000;
  --Feedback-Success---Text: #169a16;
  --Feedback-Success---Background: #169a1619;
  --Feedback-Error: #de0000;
  --Feedback-Error---Background: #de000019;
  --Feedback-Pending---Text: #DD9408;
  --Feedback-Pending---Background: #DD940819;
  --Feedback-Published---Text: #1400ff;
  --Feedback-Published---Background: #1400ff19;
  --Header-Height: 114px;
  --Footer-Height: 180px;
  --Light-Primary: #333;
  --Light-Secondary: #666;
}

$latam-audit-portal-fe-primary: mat.m2-define-palette(mat.$m2-red-palette);
$latam-audit-portal-fe-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$latam-audit-portal-fe-warn: mat.m2-define-palette(mat.$m2-red-palette);

$latam-audit-portal-fe-theme: mat.m2-define-light-theme((
  color: (
    primary: $latam-audit-portal-fe-primary,
    accent: $latam-audit-portal-fe-accent,
    warn: $latam-audit-portal-fe-warn,
  ),
  typography: mat.m2-define-typography-config(
    $font-family: "TCCC Unity Text"
  ),
));

@include mat.all-component-themes($latam-audit-portal-fe-theme);

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "TCCC Unity Text";
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  height: 100vh;
}

.container {
  margin: 0 auto;
}

// Datepicker styling
.mat-datepicker-content {
  border: 1px solid black;
  border-radius: 10px !important;
  box-shadow: unset !important;

  .mat-calendar {
    height: auto !important;

    .mat-calendar-header {
      padding: 0;

      .mat-calendar-controls {
        margin-top: 0;
      }
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-body-label {
      opacity: 0;
      padding: 8px 0 !important;
    }

    .mat-calendar-table-header {
      th {
        color: var(--Light-Primary);
      }
    }

    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      color: var(--Light-Secondary);
    }

    .mat-calendar-body-selected {
      background-color: var(--Brands-Coca-cola-Primary-Dark);
    }
  }
}
